import * as React from "react";
import { Link } from "gatsby";
import { Helmet } from "react-helmet";
import Layout from "../layouts/index";

// markup
export default function ReservedPage() {
  return (
    <Layout>
      <Helmet
        title="Add click-and-collect ordering to your restaurant's website with VIP
        Dine"
      />
      <section className="section hero ordered-hero">
        <div className="container">
          <div className="row">
            <div className="col">
              <h1>
                Click-and-collect ordering with{" "}
                <span className="product-name yellow">Ordered</span>
              </h1>
              <p>Add online food ordering feature to your web pages</p>
            </div>
          </div>
        </div>
        <div className="overlay"></div>
      </section>
      <section className="section">
        <div className="container">
          <div className="row reverse stretch">
            <div className="col image ordered-image"></div>
            <div className="col">
              <h2>
                <span className="product-name">Ordered</span>- click-and-collect
                ordering on your website
              </h2>
              <p>
                Start taking online orders in a matter of minutes. Let your
                customers use the easiest way to order and pay giving you that
                potential to sell more at really affordable costs.
              </p>
              <p>Start selling online easily and securely today.</p>
            </div>
          </div>
        </div>
      </section>

      <div class="container hr">
        <div class="hr"></div>
      </div>

      <section className="section">
        <div className="container">
          <div className="row stretch">
            <div className="col text-center">
              <h2>Get started</h2>
              <p>
                Take a look at our pricing and then register to start today!
              </p>
              <Link className="btn btn-primary" to="/pricing">
                Pricing
              </Link>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
}
